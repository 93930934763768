global.wlAngularDeps = [
  'ngAnimate',
]

global.loadAppDeps = function () {
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/finnair/locales');
  require('../../assets/javascripts/whitelabel/finnair/controllers/finnair-controller');
}

require('../application');
