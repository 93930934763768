loginModalOptions = require('../../../booking_app/types/login-modal-config')

angular.module('BookingApp')

FinnairCtrl = ($rootScope, $scope, $modal, $window, $location,
  KaligoConfig, UtilsService, SearchUrlService, AppSettings, GlobalStateService) ->
  $rootScope.finnair = {}

  $rootScope.globalState.useMembershipNoAsEmail = true
  $rootScope.globalState.hideUseAddressFromProfile = false
  $rootScope.globalState.hideFooter = false
  $rootScope.globalState.requireLoginOnCheckout = true
  $rootScope.globalState.useSimpleLoader = true
  $rootScope.globalState.checkoutHideUserDetailName = false
  $rootScope.globalState.serviceEmail = "service@kaligo.com"
  $rootScope.globalState.productType = KaligoConfig['PRODUCT_TYPE']
  $rootScope.globalState.alwaysAskForEmail = true
  $rootScope.globalState.displayMembershipAtCheckout = false
  $rootScope.globalState.pageTitle = 'Finnair'
  AppSettings.pointsCashSliderSettings.sliderLeftLabel = "Points"
  AppSettings.pointsCashSliderSettings.sliderRightLabel = "txt.cash"
  AppSettings.stripePaymentIntentsEnabled = true

  AppSettings.tenant = "Finnair Plus"
  AppSettings.checkoutRedirectToBookingsPath = true
  AppSettings.reloadOnSearch = true
  AppSettings.loginModalConfig.loginModalSize = loginModalOptions.LoginModalSizeOption.MEDIUM_SIZE
  AppSettings.hotelDetailsTemplateConfig.showBgPageColor = true
  AppSettings.newLandingPageTiles = false

  whitelabelUrl = {
    'earn': 'wl-finnair-earn',
    'redeem': 'wl-finnair-redeem'
  }

  $rootScope.enabledLocales = UtilsService.filterArrayAttr(
    $rootScope.enabledLocales,
    ['fi', 'sv', 'en'],
    'code'
  )

  getToggleImg = (type) ->
    if type == 'redeem'
      return "/finnair/earnburntoggle_icon2.png"
    else
      return "/finnair/earnburntoggle_icon1.png"

  $rootScope.globalState.toggleImg = getToggleImg(KaligoConfig['PRODUCT_TYPE'])

  $rootScope.globalState.switchProductType = (productType) ->
    $rootScope.globalState.productType = productType
    $rootScope.globalState.toggleImg = getToggleImg(productType)
    $rootScope.landingPage.url = whitelabelUrl[productType]
    return

  $rootScope.globalState.toggleProductType = () ->
    productType = if ($rootScope.globalState.productType == 'redeem') then 'earn' else 'redeem'
    $rootScope.globalState.switchProductType(productType)
    $window.location.href = SearchUrlService.createSearchUrl() if $location.$$url != '/'
    return

  $rootScope.$on "$routeChangeSuccess", (e, current, previous) ->
    $rootScope.globalState.alertMsg = "" if previous

    # catch 404 angular route path errors
    return if !current || !current.$$route

    currentController = current.$$route.controller

  triggerCloseDropdown = ->
    $(document).trigger($.Event('click.bs.dropdown.data-api'))

  $rootScope.$watch 'selectedLocale', (newvalue, oldvalue) ->
    return if !$rootScope.selectedLocale
    triggerCloseDropdown()

  $rootScope.showWelcomeBox = false

  $scope.termsOpen = false

  $scope.toggleTerms = () ->
    $scope.termsOpen = !$scope.termsOpen

  $scope.globalStateService = GlobalStateService

  return

FinnairCtrl
  .$inject = [
    '$rootScope', '$scope', '$modal', '$window', '$location',
    'KaligoConfig', 'UtilsService', 'SearchUrlService', 'AppSettings',
    'GlobalStateService'
  ]

angular.module('BookingApp')
.controller 'FinnairCtrl', FinnairCtrl
